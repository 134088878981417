import { useFetch } from 'use-http'
import { BasePage } from './BasePage/BasePage'
import { Block } from '../components/Block/Block'
import type { BlockInstance } from '../components/Block/Block'
import { API_URL } from '../util'

type PageProps = {
  title: string
  pageId: string
}

function DynamicPage(props: PageProps) {
  const options = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const { loading, data } = useFetch(`${API_URL}page/${props.pageId}`, options, [props.pageId])

  if (loading)
    return null

  return (
    <BasePage>
      {(data?.content ?? []).map((instance: BlockInstance, index: number) => {
        return <Block
          key={index}
          block={instance}
        />
      })}
    </BasePage>
  )
}

export { DynamicPage }
